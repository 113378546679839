<template>
  <div class="container-page big">
    <h2 class="title title--h2">Аналитика по Сквозным задачам</h2>
    <div class="analytics-for-cross-cutting-tasks">
      <div class="analytics-for-cross-cutting-tasks__button-wrap">
        <PerfectScrollbar>
          <ButtonComponent :class="{ active: activeItemId === 0 }" mod="border-ellipse" @click="switchTasks(0)">
            Все <span class="number">{{ totalElements }}</span>
          </ButtonComponent>
          <ButtonComponent
            v-for="item in buttonNav"
            :key="item.id"
            :class="{ active: activeItemId === item.id }"
            mod="border-ellipse"
            @click="switchTasks(item.id)"
          >
            {{ item.text }}
            <span class="number">{{ item.number }}</span>
          </ButtonComponent>
        </PerfectScrollbar>
      </div>
      <section v-for="(item, index) in tasksItems" :key="index" class="analytics-for-cross-cutting-tasks__tasks">
        <div class="analytics-for-cross-cutting-tasks__tasks-title">
          <h2 class="analytics-for-cross-cutting-tasks__h2">{{ item.name }}</h2>
          <span>{{ getTaskText(item.allTask) }}</span>
        </div>
        <div class="analytics-for-cross-cutting-tasks__tasks-items">
          <div
            class="analytics-for-cross-cutting-tasks__tasks-item"
            v-for="(list, indexList) in item.lists"
            :key="indexList"
            :id="list.id"
            v-html="list.name"
            @click="openDetailPage(list.id)"
          ></div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import wordDeclension from '@/common/mixins/wordDeclension';

  import API from '../api';

  export default {
    name: 'PageAnalyticsForCrossCuttingTasks',
    components: {
      PerfectScrollbar,
      ButtonComponent,
    },
    mixins: [wordDeclension, security],
    data() {
      return {
        activeItemId: 0,
        buttonNav: [],
        tasksItems: [],
        tasksItemsFull: [],
        totalElements: 0,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.AccessCrossCuttingTask).then((response) => {
        if (response.data) {
          API.getPageCrossCuttingTask().then((response) => {
            if (response?.data.length) {
              this.buttonNav = response.data.map((i) => ({
                text: i?.thematicBlock?.name,
                id: i?.thematicBlock?.id,
                number: i?.crossCuttingTasks.length,
              }));

              this.tasksItemsFull = response.data.map((i) => ({
                name: i?.thematicBlock?.name,
                id: i?.thematicBlock?.id,
                allTask: i?.crossCuttingTasks.length,
                lists: i?.crossCuttingTasks,
              }));

              this.buttonNav.forEach((i) => (this.totalElements += i.number));
            }
          });
        } else this.notAccess();
      });
    },
    watch: {
      tasksItemsFull(val) {
        this.tasksItems = val;
      },
    },
    methods: {
      switchTasks(id) {
        this.activeItemId = id;

        if (!id) {
          this.tasksItems = this.tasksItemsFull;
        } else {
          this.tasksItems = this.tasksItemsFull.filter((i) => i.id === id);
        }
      },

      openDetailPage(id) {
        this.$router.push({ name: 'analytics-cross-cutting-task-detail', params: { id } });
      },

      getTaskText(number) {
        return `${number} ${this.wordDeclension(number, ['задача', 'задачи', 'задач'])}`;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/title.scss';
  .analytics-for-cross-cutting-tasks {
    font-family: 'Fira Sans';

    &__button-wrap {
      margin-bottom: 35px;
      display: flex;
      align-items: center;

      .ps {
        display: flex;
        width: 100%;
        padding-bottom: 5px;
      }
    }

    &__h2 {
      font: $fira-20-M;
      display: inline-block;
      margin-right: 16px;
    }

    &__tasks {
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &-title {
        margin-bottom: 24px;

        span {
          color: $blue;
          font: $fira-20-M;
        }
      }

      &-items {
        max-width: 796px;
      }

      &-item {
        padding: 24px;
        border-radius: 4px;
        border: 1px solid $grey;
        background: $light-grey;
        font: $fira-16;
        margin-bottom: 16px;
        display: block;
        color: $base;
        transition: background-color 0.3s ease;
        cursor: pointer;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background-color: $light-grey-2;
        }
      }
    }

    @media (max-width: $laptop) {
      &__h2 {
        font-size: 16px;
        line-height: 24px;
        margin-right: 8px;
      }

      &__tasks {
        margin-bottom: 24px;

        &-title {
          margin-bottom: 16px;
          span {
            font-size: 16px;
            line-height: 24px;
          }
        }

        &-item {
          padding: 16px;
          margin-bottom: 8px;
        }
      }

      &__button-wrap {
        margin: 0 -14px 20px;

        .ps {
          padding: 0 14px 5px;
        }
      }
    }
  }
</style>
